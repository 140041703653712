import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
let router = new Router({
  routes: [
    {
      path: "/",
      component: (resolve) => require(["@/views/home/index"], resolve),
      meta: {
        title: "快易刷",
      },
    },
    {
      path: "/home",
      component: (resolve) => require(["@/views/home/index"], resolve),
      meta: {
        title: "快易刷",
      },
    },
    {
      path: "/registeredAgent",
      component: (resolve) => require(["@/views/registeredAgent/index"], resolve),
      meta: {
        title: "快易刷展业版",
        keepalive: true,
      },
    },
    {
      path: "/protocol",
      component: (resolve) => require(["@/views/registeredAgent/protocol"], resolve),
      meta: {
        title: "",
      },
    },
    {
      path: "/copdownload",
      component: (resolve) => require(["@/views/copdownload/index"], resolve),
      meta: {
        title: "快易刷展业版",
      },
    },
    {
      path: "/merdownload",
      component: (resolve) => require(["@/views/merdownload/index"], resolve),
      meta: {
        title: "快易刷",
      },
    },
    {
      path: "/privacy",
      component: (resolve) => require(["@/views/privacy/index"], resolve),
      meta: {
        title: "展业隐私政策协议",
      },
    },
    {
      path: "/agreement",
      component: (resolve) => require(["@/views/agreement/index"], resolve),
      meta: {
        title: "展业用户协议",
      },
    },
    {
      path: "/merPrivacy",
      component: (resolve) => require(["@/views/merPrivacy/index"], resolve),
      meta: {
        title: "商户隐私政策协议",
      },
    },
    {
      path: "/merAgreement",
      component: (resolve) => require(["@/views/merAgreement/index"], resolve),
      meta: {
        title: "商户用户协议",
      },
    },
    //未绑定的商户
    {
      path: "/bind",
      component: (resolve) => require(["@/views/merchant/index"], resolve),
      meta: {
        title: "商户绑定",
      },
    },
    //已绑定 需要解绑等的页面
    {
      path: "/unbind",
      component: (resolve) => require(["@/views/merchant/Unbinding"], resolve),
      meta: {
        title: "商户绑定",
      },
    },

    //解绑成功
    {
      path: "/success",
      component: (resolve) => require(["@/views/merchant/success"], resolve),
      meta: {
        title: "商户解绑",
      },
    },

    //微信认证
    {
      path: "/wxcode",
      component: (resolve) => require(["@/views/merchant/wxcode"], resolve),
      meta: {
        title: "开户意愿确认",
      },
    },

    //支付宝认证
    {
      path: "/Alipaycode",
      component: (resolve) => require(["@/views/merchant/Alipaycode"], resolve),
      meta: {
        title: "开户意愿确认",
      },
    },
    {
      path: "/merchantServiceAgreement",
      component: (resolve) => require(["@/views/agreement/merchantServiceAgreement"], resolve),
      meta: {
        title: "中付商户服务协议",
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 };
  },
});

export default router;
